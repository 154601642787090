import React from 'react';
import Layout from 'components/layout';
import SEO from "components/seo";
import { useStaticQuery, graphql } from 'gatsby';

import Section from 'sections/section';

const Home = () => {

    const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Home"}) {
                id
                title
                contentful_id,
                contentModules {
                    ... on Node {
                        id
                    }
                }
            },
            contentfulMenuItem{
                menu{
                    id,
                    name,
                    type,
                  menuItems{
                    id,
                    title,
                    url
                  }
                }
            }
        }
    `);
    const menus = data.contentfulMenuItem.menu;

    const contentModule = data.contentfulLayout.contentModules;

    return (
        <Layout menus={ menus }>
            <SEO title="Home" />
            {
                contentModule.length > 0 && 
                contentModule.map(content => (
                    <Section contentModuleId={ content.id } type={ content.__typename } key={content.id}/>
                ))
            }
        </Layout>
    );
};

export default Home;

